import { flow, cast, types } from 'mobx-state-tree';
import Dropdownmodel from './DropdownModel';

const DemoModel = types
  .model('DemoModel', {
    id: types.optional(types.integer, 0),
    key: types.optional(types.integer, 0),
    normal_input: types.optional(types.string, ''),
    input_number: types.optional(types.number, 0),
    hour_input: types.optional(types.string, ''),
    date_input: types.optional(types.string, ''),
    dropdown_input: types.optional(types.string, ''),
    text_area: types.optional(types.string, ''),
    dropdown_input_list: types.optional(types.array(Dropdownmodel), [])
  })
  .views((self) => ({}))
  .actions((self) => ({
    setDropdownList: flow(function* (type, list) {
      self[`${type}_list`] = cast(list);
    })
  }));

export default DemoModel;
