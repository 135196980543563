import React from 'react';
import { SelectOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

const AppMenus = [
  {
    key: '3',
    icon: <SelectOutlined />,
    route: Routes.DEMO_ROUTE,
    menuName: 'Demo'
  }
];

export default AppMenus;
