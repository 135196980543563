import { types, cast } from 'mobx-state-tree';

const DemoManagementUtilities = types
  .model('DemoUtilities', {
    showAddDemoModal: types.optional(types.boolean, false),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), [
      'normal_input',
      'input_number',
      'hour_input',
      'date_input',
      'dropdown_input',
      'text_area'
    ]),

    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.integer, 0),
    selectedRowsKeys: types.optional(types.array(types.string), []),
    isDownloading: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setToggleShowAddOrUpdateDemoModal(isUpdate = false) {
      self.isUpdate = isUpdate ? true : false;
      self.showAddOrUpdateDemoModal = !self.showAddOrUpdateDemoModal;
    },

    setSelectedRowsKeys(selectedRowsKeys) {
      self.selectedRowsKeys = cast(selectedRowsKeys);
    },

    setIsDownloading(isDownloading) {
      self.isDownloading = isDownloading;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    },
    setUpdateId(id) {
      self.updateId = id;
    },

    getDateFormat(locale) {
      switch (locale) {
        case 'de':
          return 'DD.MM.YYYY';
        default:
          return 'DD-MM-YYYY';
      }
    }
  }));

export default DemoManagementUtilities;
